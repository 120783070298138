<template lang="pug">
  div(class="root coment-container")
    <div class="center">
      <p style="margin-bottom: 0px;"><b>コメント</b></p>
    </div>
    div(class="container2")
      #comment-list-root
        div(v-for="(item, index) in commentList")
          template-vars(:itemEx="JSON.parse(item.payload.extension)")
            template(#default="{itemEx}")
              .commentTable
                span(v-if="commentList.length - 1 === index" id="comment-list-last")
                div(v-if="item.payload.data === 'text'")
                  <div class="normalcomm">
                    <table>
                      <tbody>
                        div(class="comment-content" v-if="isMintInited")
                          div(class="comment-account-img" @click="onAvatar(item)")
                            pan-thumb(:avatar="itemEx.AVATAR" :attribute="itemEx.ATTRIBUTE" :current_title="itemEx.CURRENT_TITLE" :isStreamer="isStreamer(itemEx)"  size="40px")
                          div(class="comment-name-text")
                            <span v-if="giftSendUserList.indexOf(itemEx.PROFILE_ID) !== -1" style="color: #e03e2d;" class="comment-name">{{itemEx.NICKNAME}}<img v-if="itemEx.IS_FIRST" src="/img/newuser.png" alt="" width="10" height="15" /></span>
                            <span v-else style="color: #88623f;" class="comment-name">{{itemEx.NICKNAME}}</span><img v-if="itemEx.IS_FIRST" src="/img/newuser.png" alt="" width="10" height="15" />
                            <span class="comment-text">{{replaceStar(item.payload.description)}}</span>
                      </tbody>
                    </table>
                  </div>
                div(v-if="item.payload.data === 'gift'")
                  <div class="newcomm" style="background: #fae8ed;border-radius: 0.5rem;" width="280px">
                    <table class="comment-table">
                      <tbody>
                        div(class="comment-content")
                          div(class="comment-account-img" @click="onAvatar(item)")
                            pan-thumb(:avatar="itemEx.AVATAR" :attribute="itemEx.ATTRIBUTE" :current_title="itemEx.CURRENT_TITLE" :isStreamer="isStreamer(itemEx)" size="40px")
                          div(class="comment-content-left")
                            div(class="comment-name-text")
                              <span style="color: #88623f;"><span style="color: #e03e2d;">{{itemEx.NICKNAME}}</span><img v-if="itemEx.IS_FIRST" src="/img/newuser.png" alt="" width="10" height="15" /><span class="point-amount" v-if="itemEx.RAW.coupon_amount > 0"><img style="margin-right: 2px;" src="/img/P_pink_icon.svg" alt="" width="12" height="18" />{{itemEx.RAW.coupon_amount.toLocaleString()}}</span></span>
                              <span class="comment-text">{{item.payload.description}}</span>
                              <span v-if="itemEx.GIFT_ID" style="color: #808080;" class="comment-text">{{itemEx.GIFT_ID}}を送りました！</span>
                            div(class="comment-gift-point")
                              div(class="comment-gift")
                                <img :src="itemEx.giftIcon" alt="" width="70" height="70" /></span>
                                <span> × {{ itemEx.RAW.amount }}</span>
                              div(v-if="itemEx.RAW.ratio > 0" class="comment-point-content")
                                template(v-for="boostPoint in { boostPoint: boostRatio(itemEx)}")
                                  div(v-if="boostPoint > 0" class="comment-point-total-img")
                                      <img src="/img/r-number/total.png">
                                  div(class="comment-point")
                                    div(v-if="boostPoint > 0" class="comment-point-img")
                                      <img :src="'/img/r-number/r' + String(boostPoint) + '.png'">
                                      <img src="/img/r-number/r0.png">
                                      span(class="comment-point-percent")
                                        <img src="/img/r-number/r_percent.png">
                                      span(class="comment-point-gif")
                                        <img src="/img/r-number/yajirushi_hikari-loop.gif">
                                    div(v-if="boostPoint > 0" class="comment-point-img")
                                      <img :src="'/img/r-number/r' + String(itemEx.RAW.ratio / 10 - boostPoint) + '.png'">
                                      <img src="/img/r-number/r0.png">
                                      span(class="comment-point-percent")
                                        <img src="/img/r-number/r_percent.png">
                                      span(class="comment-point-gif")
                                        <img src="/img/r-number/yajirushi_hikari-loop.gif">
                                    <div class="comment-point-img">
                                      <img :src="'/img/r-number/r' + String(itemEx.RAW.ratio / 10) + '.png'">
                                      <img src="/img/r-number/r0.png">
                                      span(class="comment-point-percent")
                                        <img src="/img/r-number/r_percent.png">
                                      span(class="comment-point-gif")
                                        <img src="/img/r-number/yajirushi_hikari-loop.gif">
                                    </div>
                        </tbody>
                    </table>
                  </div>
</template>

<style src="./CommentList.scss" lang="scss" scoped>

</style>

<script>
import TIM from 'tim-js-sdk';
import VueScrollTo from 'vue-scrollto';
import _ from 'lodash';
import PanThumb from '@/components/PanThumb'
import Mint from 'mint-filter'
import { getNgWordList } from '@/api/setting'

export default {
  name: "LiveComponentCommentList",
  components: {
    PanThumb,
    TemplateVars: {
      functional: true,
      render: (h, {props, scopedSlots}) => h('div', scopedSlots.default(props)),
    },
  },
  data() {
    return {
      ui: {
        inputComment: ''
      },
      initSuccess: false,
      forbiddenWordList:[],
      mint:{},
      isMintInited:false
    }
  },
  props: {},
  computed: {
    commentList() {
      // streamerのメッセージをスキップ。
      if(this.$store.state.live.commentList.length > 0){
        const list = this.$store.state.live.commentList.filter(n => {
          const extension = JSON.parse(n.payload.extension);
          const isStreamerMessage = extension.PROFILEABLE_TYPE === 'streamer'
          return !isStreamerMessage;
        })
        return list;
      }
      return this.$store.state.live.commentList
    },
    giftSendUserList() {
      return this.$store.state.live.once_send_gift_profile_ids;
    },
  },
  watch: {},
  mounted() {},
  async created() {
  },
  updated() {},
  async beforeDestroy() {
    this.removeEventListenerToTIM();
  },
  methods: {
    async init() {
      this.addEventListenerToTIM();
      this.initMint()
    },
    replaceStar(txt){
      const { pass, text } = this.mint.filterSync(txt)
      return pass ? text:text.replace(/([*])\1*/g,'☆☆☆')
    },
    // Forbidden word
    async initMint(){
      this.isMintInited = false
      try {
        const { data } = await getNgWordList(this.$store.state.user.id)
        this.forbiddenWordList = data.word_list || []
      } catch {
        this.forbiddenWordList = []
      }
      // console.log({ngword:this.forbiddenWordList})
      this.mint = new Mint(this.forbiddenWordList)
      this.isMintInited = true
    },
    isStreamer(item){
      return item.PROFILEABLE_TYPE === 'STREAMER';
    },
    addEventListenerToTIM() {
      this.$store.state.live.insTIM?.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, this.onTimFunc1);
    },

    removeEventListenerToTIM() {
      this.$store.state.live.insTIM?.off(TIM.EVENT.CONVERSATION_LIST_UPDATED, this.onTimFunc1);
    },

    onAvatar(item) {
      this.$emit('onavatar', item);
    },
    boostRatio(itemEx){
      if( !itemEx.RAW.ratio > 0 ){
        return 0;
      }
      // 無理やりブースト値を計算する ゴッドギフトも全て10
      if( ( this.isAttributeGift(itemEx.RAW.type) || this.isGodGift(itemEx.RAW.type) ) && Number(itemEx.RAW.ratio) > 10 ){
        return 1;
      }
      return 0;
    },
    isAttributeGift(giftType){
      const giftList =  this.$store.state.system.enum_list.gift_type_list;
      const attributeGiftList = [
        giftList.SHOW_FIRE,
        giftList.SHOW_ICE,
        giftList.SHOW_WIND,
        giftList.SHOW_EARTH,
        giftList.BEAST_PHOENIX,
        giftList.BEAST_PEGASUS,
        giftList.BEAST_DRAGON,
        giftList.BEAST_WHITETIGER,
      ]
      return attributeGiftList.includes(giftType);
    },
    isGodGift(giftType){
      const giftList =  this.$store.state.system.enum_list.gift_type_list;
      const attributeGiftList = [
        giftList.SUPERLATIVE_ZEUS,
        giftList.SUPERLATIVE_GODDESS,
      ]
      return attributeGiftList.includes(giftType);
    },

    onTimFunc1(event) {
      const target = _.chain(event.data).find({conversationID: 'GROUP' + this.$store.state.live.info.message_group}).value();
      if (target && target.lastMessage) {
        if (!_.find(this.commentList, {lastSequence: target.lastMessage.lastSequence})) {
          if (target.lastMessage.fromAccount !== '@TIM#SYSTEM') {
            this.$store.dispatch('live/timUpdateCommentList', _.cloneDeep(target.lastMessage));
            this.$nextTick(() => {
              VueScrollTo.scrollTo('#comment-list-last', 100, {
                container: '#comment-list-root',
              })
            })
          }
        }
      }
    }
  }
};
</script>
