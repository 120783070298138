<template lang="pug">
  .root
    component-header-index
    div(class="container-flex")
      div(class="content-left")
        //- Animation Test
        //- button(@click="playAnimPixi()") playAnimPixi

        div(class="left-button")
          div(class="left-button-item")
            img(@click="onShare()" src="/img/kakusan.png")
            div(style="display:none;")
                share-link(ref="shareLink" :text="shareText" :url="shareUrl")
          div(v-if="$store.state.live.info.target.id" class="left-button-item")
            img(v-if="isLiveNormal" @click="onTarget()" src="/img/target_icon.png")
            div(v-if="isLiveNormal") 達成率: {{ ($store.state.live.info.target.percentString || 0) + '%' }}

          div(class="left-button-item")
            div(v-if="isLiveEvent") 現在順位: {{ $store.state.live.liveEventRank }}
            //-img(v-if="isLiveEvent" @click="onEvent()" src="/img/event_icon.png")

          div(class="left-button-item")
            img(v-if="isLiveClubfun" @click="onClubfun()" src="/img/crfun_icon.png")

        //- select(disabled="true" class="input-g-w-border")
          option(value="") 画面フィルター

        Comment(ref="comment")

        //- h1(v-if="isLoading") Loading...

        .stream-container
          //- 配信完了であればリロードアイコンを出す
          div(v-if="isLiveOver" class="over-screen")
          VideoRect.absolute(ref="videoRect")
          //--------- Animation Test------------
          //- .pixi.absolute(ref="rectPixi" :style="{width: '30vw', height: '15vw', zIndex: 20}")
          //------------------------------------
          .pixi.absolute(ref="rectPixi")
          div(v-if="backgroundImgShow" v-bind:class="motionBackground")
          <div v-if="isAllGiftBooster || isBoosterAll" class="bottomleft-frame"><img src="/img/mission-complete/flame_rainbow.gif"></div>
          <div v-if="isBoosterFire" class="bottomleft-frame"><img src="/img/mission-complete/flame_aka.gif"></div>
          <div v-if="isBoosterIce" class="bottomleft-frame"><img src="/img/mission-complete/flame_ao.gif"></div>
          <div v-if="isBoosterWind" class="bottomleft-frame"><img src="/img/mission-complete/flame_pink.gif"></div>
          <div v-if="isBoosterEarth" class="bottomleft-frame"><img src="/img/mission-complete/flame_cha.gif"></div>
          //- missionCompleteアイコン表示
          MissionComplete(v-if="isPlayingMissionComplete" :isBeast="isMissionCompleteForBeast")
          <div class="bottomleft">
            <div v-if="isVisibleAttribute" class="lamp">
                <img :src="attributeFireImage()"/>
                <img :src="attributeIceImage()"/>
                <img :src="attributeWindImage()"/>
                <img :src="attributeEarthImage()"/>
            </div>
            <div v-if="isVisibleBeast" class="lamp">
                <img :src="beastPhoenixImage()"/>
                <img :src="beastDragonImage()"/>
                <img :src="beastPegasusImage()"/>
                <img :src="beastWhiteTigerImage()"/>
            </div>
          </div>

          <div v-if="isLiveClubfun" class="clubfun-container">
            .pixi.absolute(ref="rectPixi")
            div.bottomleft-clubfun
              div.bottomleft-coins(v-if="!clubfunPercentageThousands")
                div.coins-icons(v-for="index in clubfunPercentageHundreds")
                  <img src="/img/clubfun_coin_par/10.png">
                div.coins-icons(v-if="clubfunPercentageTens")
                  <img :src="getClubfunPercentageTensCoinImg()">
              div.bottomleft-coins(v-if="clubfunPercentageThousands")
                div.coins-icons(v-for="index in 10")
                  <img src="/img/clubfun_coin_par/10.png">
              div.bottomright-number
                <div class="number-icons" v-if="clubfunPercentageThousands > 0"><img :src="getClubfunPercentageThousandsImg()" ></div>
                <div class="number-icons" v-if="clubfunPercentageHundreds > 0 || clubfunPercentageThousands > 0"><img :src="getClubfunPercentageHundredsImg()" ></div>
                <div class="number-icons" v-if="clubfunPercentageTens > 0 || clubfunPercentageHundreds > 0"><img :src="getClubfunPercentageTensImg()" ></div>
                <div class="number-icons"><img :src="getClubfunPercentageOnesImg()" ></div>
                <div class="number-icons-par"><img src="/img/clubfun_coin_par/g_.png" ></div>
          </div>
          //- <div v-if="(isLiveNormal || isLiveEvent)">
          //-   <div v-if="streamTitle" class="topleft"><p style="text-align: center;"><b>{{ streamTitle }}</b></p></div>
          //- </div>
          <div v-if="isLiveNormal || isLiveEvent">
            <div v-if="!boosterTitleShow && streamTitle" class="topleft"><p style="text-align: center;"><b v-html="streamTitle"></b></p></div>
            <div v-if="boosterTitleShow" class="topleft">
              <vue-seamless-scroll :data="scrollboosterMessage" :class-option="optionLeft" class="seamless-warp2">
                <ul class="item">
                    <li v-for="item in scrollboosterMessage">
                      <span v-if="isBoosterAll"> ★★★全属性{{item.title}} </span>
                      <span v-if="isBoosterFire">★★★炎属性{{item.title}} </span>
                      <span v-if="isBoosterIce"> ★★★氷属性{{item.title}} </span>
                      <span v-if="isBoosterWind"> ★★★風属性{{item.title}} </span>
                      <span v-if="isBoosterEarth"> ★★★地属性{{item.title}} </span>
                    </li>
                </ul>
              </vue-seamless-scroll>
            </div>
            //- <div class="topleft"><p style="text-align: center;"><b>{{ boosterMessage }}</b></p></div>
          </div>
          <div v-if="isLiveClubfun">
            <div v-if="!clubfunTitleShow && streamTitle" class="topleft"><p style="text-align: center;"><b v-html="streamTitle"></b></p></div>
            <div v-if="clubfunTitleShow" class="topleft">
              <vue-seamless-scroll :data="scrollClubfunMessage" :class-option="optionLeft" class="seamless-warp2">
                <ul class="item">
                  <li v-for="item in scrollClubfunMessage">
                    <span> ★★★ {{item.title}} %達成★★★ </span>
                  </li>
                </ul>
              </vue-seamless-scroll>
            </div>
          </div>
          <div v-if="isLivePaid">
            <div v-if="streamTitle" class="topleft"><p style="text-align: center;"><b v-html="streamTitle"></b></p></div>
          </div>
          <div v-if="videoGiftShow">
            <div class="topleftgift">
              <div class="giftsent">
                <div class="giftsent-icon" style="margin-right: 4px;">
                  <img :src="lastGiftExtension.giftIcon" />
                  <span>×</span>
                  <span style="margin-right: 0px;">{{lastGiftExtension.RAW.amount }}</span>
                </div>
                <div class="giftsent-right">
                  <div>
                    <span>{{lastGiftExtension.NICKNAME}} </span>
                    <span v-if="isLiveNormal || isLiveEvent" class="gift-point">
                      <span data="" class="badge badge-pink badge-pill" style="color: #fff;">P</span>{{lastGiftExtension.RAW.coupon_amount.toLocaleString()}}
                    </span>
                  </div>
                  <div v-if="lastGiftExtension.GIFT_ID">
                    <span>{{lastGiftExtension.GIFT_ID}}を送りました！</span>
                  </div>
                  <div v-else>
                    <span>コインを送りました！</span>
                  </div>
                  <div v-if="isLiveNormal || isLiveEvent">
                    <span><p class="giftsent-comment">{{lastGiftExtension.description}}</p></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        div(v-if="isDev")
          button(@click="playFireEffect()") 炎テスト
          button(@click="playSnowEffect()") 雪テスト
          button(@click="playWindEffect()") 風テスト
          button(@click="playEarthEffect()") 土テスト
          button(@click="playZeus()") Zeusテスト
          button(@click="playBall()") Ballテスト
          button(@click="playMeerkat()") Meerkatテスト
          button(@click="playGoddess()") Goddessテスト
          button(@click="playPhoenix()") Phoenixテスト
          button(@click="playPinkShit()") PinkShitテスト
          button(@click="playIceDragon()") IceDragonテスト
          button(@click="playDolphin()") Dolphinテスト
          button(@click="playWhale()") Whaleテスト
          button(@click="playWildPig()") WildPigテスト
          button(@click="playTiger()") Tigerテスト
          button(@click="playFlyHorse()") FlyHorseテスト
          button(@click="playHanabi1()") Hanabi1テスト
          button(@click="playHanabi2()") Hanabi2テスト
        div(class="stream-detail")
          div(class="stream-detail-room-name") 配信ルーム名: {{ $store.state.live.info.room_info.name ? $store.state.live.info.room_info.name : stateRoomName}}
          div
            img(src="/img/profile_icon_blue.svg" style="margin-right: 4px; height: 16px; width: 16px;")
            span 視聴人数: {{ onlineCount || 0 }}
          div(v-if="!isLiveClubfun")
            img(src="/img/P_pink_icon.svg" style="margin-right: 6px; height: 16px; width: 16px;")
            span 累計ポイント: {{ livePoint ? livePoint.toLocaleString() : 0 }}

        div(class="stream-bottom")
          div(v-if="!beforeStream" class="stream-due")
            img(style="height: 14px; width: 14px; margin-right: 2px;" src="/img/clock_icon_green.svg")
            span(v-if="$store.state.live.info.started_at") {{$store.state.live.info.started_at | moment("YYYY年MM月DD日HH:mm") }}
            span ～
            span(v-if="isLiveOver && $store.state.live.info.ended_at") {{$store.state.live.info.ended_at | moment("YYYY年MM月DD日HH:mm")  }}
            span(v-else-if="!isLiveOver && isLiveClubfun && $store.state.live.info.expire_time") {{$store.state.live.liveClubfun.end_time | moment("YYYY年MM月DD日HH:mm")  }}
            span(v-else-if="!isLiveOver && $store.state.live.info.expire_time") {{$store.state.live.info.expire_time | moment("YYYY年MM月DD日HH:mm") }}
            span(v-else) (予定)

          div(v-if="beforeStream" class="stream-bottom-select")
            div(v-if="!isLiveClubfun && !isLivePaid && !isLiveEvent" class="select-box")
              select(v-model="liveDurationPlan" class="input-g-w-border")
                option(value="") [選択]
                option(value="1") 1時間
                option(value="2") 2時間
                option(value="3") 3時間
            div(v-if="isLiveEvent" class="caution-t") 1時間&emsp;※イベント配信は1日あたり1時間を2回しか行うことができません
          div(class="stream-bottom-button")
            div(id="start-button")
              button(v-if="beforeStream" :disabled="!initialzed || isLiveOver" @click="onStartLive()" v-preventReClick="3000" class="start-button")
            div(id="stop-button")
              button(v-if="!beforeStream || isLiveOver" :disabled="isLiveOver" @click="setPanelCloseConfirm(true)" class="stop-button")
        div(v-if="isLiveClubfun") 各コインの販売枚数: {{ $store.state.live.liveClubfun["current_coin_amount_1-17-1"] }}-{{ $store.state.live.liveClubfun["current_coin_amount_1-17-2"] }}-{{ $store.state.live.liveClubfun["current_coin_amount_1-17-3"] }}-{{ $store.state.live.liveClubfun["current_coin_amount_1-17-4"] }}-{{ $store.state.live.liveClubfun["current_coin_amount_1-17-5"] }}

        div(v-if="ui.stopConfirm" class="popup")
          button(@click="setPanelCloseConfirm(false)" class="close-button") <i class="fas fa-times"></i>
          div(v-if="isLiveEvent") イベント参加期間中の配信は1日あたり1時間を2回しか行うことができませんが、<br>本当に配信停止してもよろしいですか？
          div(v-if="isLiveClubfun") クラファン配信は配信停止すると再開できません。<br>本当に配信停止してもよろしいですか？
          div(v-if="!isLiveEvent && !isLiveClubfun") 配信停止しますか？
          div(class="button-gemini")
            img(@click="stopLive()" v-preventReClick="3000" class="img-button" src="/img/yes_p.png")
            img(@click="setPanelCloseConfirm(false)" class="img-button" src="/img/no_p.png")

      div(class="content-right")
        div.external-encoder
          div.external-encoder-title サーバー: <a :data-clipboard-text="serverUrl" class="fas fa-copy url"></a>
          div.external-encoder-text {{ isStarted ? (serverUrl || "取得に失敗しました") : "配信開始後に取得できます"}}
          div.external-encoder-title ストリームキー: <a :data-clipboard-text="streamKey" class="fas fa-copy key"></a>
          div.external-encoder-text {{ isStarted ? (streamKey || "取得に失敗しました") : "配信開始後に取得できます" }}
        div(v-if="ui.profile")
          component-profile-index(:profile_id="showingProfileId" @profileclose="onProfileClose()")
        //- div(v-if="ui.block")
        //-   div(class="right-content-border")
        //-     button(@click="onClose()" class="close-button") <i class="fas fa-times"></i>
        //-     div 本当にブロックしますか？
        //-     div(class="button-gemini")
        //-       button(@click="onAvatarConfirm()" class="pink-button") はい
        //-       button(@click="onClose(false)" class="pink-button") いいえ
        div(v-if="ui.target")
          div(class="right-content-border")
            button(@click="onPanelClose()" class="close-button") <i class="fas fa-times"></i>
            div(class="t-c m-b target-center-item") 現在累計{{ $store.state.live.info.target.current_point }}pt/{{ $store.state.live.info.target.percentString }} ％達成
            //- div(class="target-center-item rank-title") ターゲット名:
            div.rank-title {{ $store.state.live.info.target.name }}
            div(class="target-img")
              img(:src="$store.state.system.config.storage_addr_list.public + $store.state.live.info.target.banner_store_path")
            div(class="rank-title") 目標ポイント:{{ $store.state.live.info.target.target_point.toLocaleString() }}pt
            //- div(class="rank-title") チャレンジ内容
            div.rank-content {{ $store.state.live.info.target.content }}
            div(v-if="$store.state.live.info.target.ranking && $store.state.live.info.target.ranking.length >0")
              div.rank-title ランキング
              div(class="live-user-rank")
                div.rank-item(v-for="(item,index) in $store.state.live.info.target.ranking")
                  div.account-img(@click="onAvatarClick(item.profile.id)")
                    <pan-thumb :avatar="item.profile.avatar_store_path" :attribute="getAttribute(item)" :current_title="item.viewer_info.current_title"  size="70px"/>
                    div(v-if="index < 3" class="rank-top-three")
                      span(data="" class="badge-rank" :class="index == 0 ? 'badge-gold' : index == 1 ? 'badge-silver' : index == 2 ? 'badge-copper' : ''") {{index + 1}}
                  div.rank-item-name {{ item.profile.account_name }}
                  div.rank-item-point
                    <span class="badge badge-pink badge-pill" style="color: #fff"> P </span> {{ item.total_points.toLocaleString() }}
                  //- button.pink-button-rank(v-if="item.profile.id && isNotSelf(item.profile.id)" @click="item.is_following ? onFollowCancel(item.profile.id): onFollow(item.profile.id)") {{ item.is_following ? 'ﾌｫﾛｰ済み' : 'ﾌｫﾛｰする' }}
        CommentList(v-show="!panelShow" ref="commentlist" @onavatar="onCommentAvatarClick")
    component-footer-index
</template>

<style src="./Base.scss" lang="scss" scoped>

</style>

<script>
import { getLiveDurationStrCheckFalse } from "@/utils/tools";
import { isDev, isLocal, isStg } from "@/utils/tools";
import Comment from './Comment'
import CommentList from './CommentList'
import VideoRect from './VideoRect'
import MissionComplete from './MissionComplete'
import { getLiveAttrInfo, getLiveAllListS1, getLiveListEventEntryExpiry } from '@/api/live'
// import { addBlock } from '@/api/block';
import Clipboard from 'clipboard';
import { getImageUrl,serverMoment } from '@/utils/tools'
import PanThumb from '@/components/PanThumb'
import ShareLink from '@/components/ShareLink'
import _ from 'lodash'
import { initPixi,playSpineAnimation } from '@/utils/spine'
import moment from 'moment'

import * as PIXI from 'pixi.js'
window.PIXI = PIXI
import {Emitter} from 'pixi-particles';
import { getFireEffect, getSnowEffect, getWindAirEffect, getWindFlowerEffect, getWindFlower2Effect } from '@/api/particles';

PIXI.utils.skipHello()
import 'pixi-spine'

import {LIVE_TYPE} from '../helper/def'

export default {
  name: "LiveComponentBase",
  components: {
    Comment,
    CommentList,
    VideoRect,
    MissionComplete,
    ShareLink,
    PanThumb
  },
  filters:{
    getAvatarUrl(url){
      return getImageUrl(url)
    }
  },
  data() {
    return {
      share: '',
      shareText: '',
      shareUrl: '',
      playQ:[],
      livePoint: 0,
      effectDuration:5000,
      updateInterval: undefined,
      prevAttrInfoActive: false,
      showingProfileId:-1,
      prevPercentage: 0,
      ui: {
        stopConfirm: false,
        target: false,
        block: false,
        profile:false,
      },
      objDynamic: undefined,
      // isLoading: true,

      liveDurationPlan: 1,
      commentItem: undefined,
      timerLocalUpdate: 0,
      timerSyncTarget: 0,
      timerSyncEventRank: 0,
      clipboard:'',
      clipboard2:'',
      boosterTitleShow: false,
      clubfunTitleShow: false,
      scrollboosterMessage: [{'title' : 'ブースター発生中★★★'}],
      scrollClubfunMessage: [],
      videoGiftShow: false,
      backgroundImgShow: false,
      giftType: '',
      firstFlag: true,
      initialzed: false,

      expireTime:undefined,
      systemTimeStamp:0,
      LOCAL_UPDATE_INTERVAL_SECOND:3,

      particleContainer: null,
      effectFireEmmiter: undefined,
      effectFireOption: undefined,
      effectSnowEmmiter: undefined,
      effectSnowOption: undefined,
      effectWindAirEmmiter: undefined,
      effectWindAirOption: undefined,
      effectWindFlowerEmmiter1: undefined,
      effectWindFlowerEmmiter2: undefined,
      effectWindFlowerOption: undefined,
      effectWindFlower2Option: undefined,
      audio: new Audio(),
      attributeAudio: new Audio(),
      motionAudio: new Audio(),
      isPlayingMissionComplete: false,
      isMissionCompleteForBeast: false,
      isClubfunInit: true,
    }
  },
  props: {
    liveType: {
      type: Symbol,
      default: LIVE_TYPE.NORMAL
    }
  },
  computed: {
    isLiveEvent() {
      return this.liveType === LIVE_TYPE.EVENT
    },
    isLiveNormal() {
      return this.liveType === LIVE_TYPE.NORMAL
    },
    isLiveClubfun() {
      return this.liveType === LIVE_TYPE.FUN_CLUB
    },
    isLivePaid() {
      return this.liveType === LIVE_TYPE.PAID
    },
    isStarted(){
      return this.$store.getters.enum_list.live_status_list.ONGOING == this.$store.state.live.info.status || this.$store.getters.enum_list.live_status_list.PREPARATION == this.$store.state.live.info.status;
    },
    serverUrl(){
      return this.$store.state.live.stream.settings.server
    },
    streamKey(){
      return this.$store.state.live.stream.settings.key
    },
    onlineCount() {
      return this.$store.state.live.onlineCount;
    },
    stateRoomName() {
      if (this.isLivePaid) {
        return this.$store.state.live.state_info.pay_title
      } else if (this.isLiveClubfun) {
        return this.$store.state.live.state_info.clubfun_title
      }
      return this.$store.state.live.state_info.normar_title;
    },
    isLiveOver() {
      return this.$store.getters.enum_list.live_status_list.ENDED === this.$store.state.live.info.status
    },
    beforeStream() {
      return !this.isStarted && !this.isLiveOver
    },
    panelShow(){
      return this.ui.target || this.ui.profile
      // return this.ui.block || this.ui.target || this.ui.profile
    },
    streamTitle() {
      return this.StreamerComment
    },
    StreamerComment() {
      // 配信者メッセージ
      const streamerMessageItem = _.findLast(this.$store.state.live.commentList, n => {
          const extension = JSON.parse(n.payload.extension);
          return extension.PROFILEABLE_TYPE === 'streamer';
        })
      if (streamerMessageItem) {
        return _.isString(streamerMessageItem.payload.description) ? streamerMessageItem.payload.description.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1' target='view_window'>$1</a>") : '';
      }
      return null
    },
    optionLeft () {
      return {
        direction: 2,
        singleWidth: 720,
        limitMoveNum: 1,
        step: 3,
        hoverStop: false,
        waitTime: 0,
      }
    },
    isAllGiftBooster() {
      if (
        this.$store.state.live.liveAttributeInfo.all_gift_booster_time &&
        moment(this.$store.state.live.liveAttributeInfo.all_gift_booster_time).isAfter(moment())
      ) {
        return true;
      }
      return false;
    },
    isBoosterAll() {
      if( this.isAllGiftBooster ){
        return false;
      }
      if(
        this.$store.state.live.liveAttributeInfo.booster_time &&
        moment(this.$store.state.live.liveAttributeInfo.booster_time).isAfter(moment()) &&
        this.$store.state.live.liveAttributeInfo.booster_type.value == this.$store.state.system.enum_list.gift_type_list.BOOSTER_ALL
      ){
        return true;
      }
      return false;
    },
    isBoosterFire() {
      if( this.isAllGiftBooster ){
        return false;
      }
      if(
        this.$store.state.live.liveAttributeInfo.booster_time &&
        moment(this.$store.state.live.liveAttributeInfo.booster_time).isAfter(moment()) &&
        this.$store.state.live.liveAttributeInfo.booster_type.value == this.$store.state.system.enum_list.gift_type_list.BOOSTER_FIRE
      ){
        return true;
      }
      return false;
    },
    isBoosterIce() {
      if( this.isAllGiftBooster ){
        return false;
      }
      if(
        this.$store.state.live.liveAttributeInfo.booster_time &&
        moment(this.$store.state.live.liveAttributeInfo.booster_time).isAfter(moment()) &&
        this.$store.state.live.liveAttributeInfo.booster_type.value == this.$store.state.system.enum_list.gift_type_list.BOOSTER_ICE
      ){
        return true;
      }
      return false;
    },
    isBoosterWind() {
      if( this.isAllGiftBooster ){
        return false;
      }
      if(
        this.$store.state.live.liveAttributeInfo.booster_time &&
        moment(this.$store.state.live.liveAttributeInfo.booster_time).isAfter(moment()) &&
        this.$store.state.live.liveAttributeInfo.booster_type.value == this.$store.state.system.enum_list.gift_type_list.BOOSTER_WIND
      ){
        return true;
      }
      return false;
    },
    isBoosterEarth() {
      if( this.isAllGiftBooster ){
        return false;
      }
      if(
        this.$store.state.live.liveAttributeInfo.booster_time &&
        moment(this.$store.state.live.liveAttributeInfo.booster_time).isAfter(moment()) &&
        this.$store.state.live.liveAttributeInfo.booster_type.value == this.$store.state.system.enum_list.gift_type_list.BOOSTER_EARTH
      ){
        return true;
      }
      return false;
    },
    // 属性
    isAttributeFire() {
      if(
        this.$store.state.live.liveAttributeInfo.fire_time &&
        moment(this.$store.state.live.liveAttributeInfo.fire_time).isAfter(moment())){
        return true;
      }
      return false;
    },
    isAttributeIce() {
      if(
        this.$store.state.live.liveAttributeInfo.ice_time &&
        moment(this.$store.state.live.liveAttributeInfo.ice_time).isAfter(moment())){
        return true;
      }
      return false;
    },
    isAttributeWind() {
      if(
        this.$store.state.live.liveAttributeInfo.wind_time &&
        moment(this.$store.state.live.liveAttributeInfo.wind_time).isAfter(moment())){
        return true;
      }
      return false;
    },
    isAttributeEarth() {
      if(
        this.$store.state.live.liveAttributeInfo.earth_time &&
        moment(this.$store.state.live.liveAttributeInfo.earth_time).isAfter(moment())){
        return true;
      }
      return false;
    },
    isVisibleAttribute(){
      if( this.isAttributeFire || this.isAttributeIce || this.isAttributeWind || this.isAttributeEarth ){
        return true;
      }
      return false;
    },
    // 魔獣
    isBeastPhoenix(){
      if(
        this.$store.state.live.liveAttributeInfo.phoenix_time &&
        moment(this.$store.state.live.liveAttributeInfo.phoenix_time).isAfter(moment())){
        return true;
      }
      return false;
    },
    isBeastDragon(){
      if(
        this.$store.state.live.liveAttributeInfo.dragon_time &&
        moment(this.$store.state.live.liveAttributeInfo.dragon_time).isAfter(moment())){
        return true;
      }
      return false;
    },
    isBeastPegasus(){
      if(
        this.$store.state.live.liveAttributeInfo.pegasus_time &&
        moment(this.$store.state.live.liveAttributeInfo.pegasus_time).isAfter(moment())){
        return true;
      }
      return false;
    },
    isBeastWhiteTiger(){
      if(
        this.$store.state.live.liveAttributeInfo.white_tiger_time &&
        moment(this.$store.state.live.liveAttributeInfo.white_tiger_time).isAfter(moment())){
        return true;
      }
      return false;
    },
    isVisibleBeast(){
      if( this.isBeastPhoenix || this.isBeastDragon || this.isBeastPegasus || this.isBeastWhiteTiger ){
        return true;
      }
      return false;
    },
    boosterMessageShow(){
      if(this.isBoosterAll){
        return 1;
      }
      if(this.isBoosterFire) {
        return 2;
      }
      if(this.isBoosterIce) {
        return 3;
      }
      if (this.isBoosterWind) {
        return 4;
      }
      if (this.isBoosterEarth) {
        return 5;
      }
      return false;
    },
    lastGiftExtension() {
      let item;
      if (this.$store.state.live.commentList.length != 0) {
        item = this.$store.state.live.commentList[this.$store.state.live.commentList.length-1];
      }
      if (item && item.payload && item.payload.data === 'gift') {
        let temp = JSON.parse(item.payload.extension);
        if (item.payload.description){
          temp.description = item.payload.description;
        }
        return temp;
      }
      return null
    },
    isDev() {
      return process.env.VUE_APP_ENV === 'DEV'
    },
    clubfunPercentageOnes() {
      return this.$store.state.live.current_percentage.ones
    },
    clubfunPercentageTens() {
      return this.$store.state.live.current_percentage.tens
    },
    clubfunPercentageHundreds() {
      return this.$store.state.live.current_percentage.hundreds
    },
    clubfunPercentageThousands() {
      return this.$store.state.live.current_percentage.thousands
    },
    motionBackground() {
      switch(this.giftType) {
        case 'NvShen':
          return {
            backgroundGoddess: true,
          }
        case 'Dolphin':
        case 'Whale':
          return {
            backgroundSkyblue: true,
          }
        default:
          return {
            backgroundNon: true,
          }
      }
    },
  },
  watch: {
    lastGiftExtension(newVal){
      if (newVal) {
        // ライブポイントの更新
        this.livePoint = newVal.RAW.live_point;
        if (this.firstFlag) {
          this.firstFlag = false
          return
        }else{
          clearTimeout(this.giftTimer);
          let _this = this;
          _this.videoGiftShow = true;
          // クラファン
          if( this.isLiveClubfun ){
            // 10%毎の壁を越えた瞬間
            if( Math.floor(this.prevPercentage/10) != Math.floor(newVal.RAW.current_percentage/10) && !newVal.RAW.is_goal_effect ){
              this.audio.src = require("../../../assets/voice/10up.mp3");
              this.audio.play();
            }
            // 花火演出 100%毎の壁を越えた瞬間
            if( newVal.RAW.is_goal_effect ){
              this.playClubfunTitle()
              // 花火の演出
              this.playHanabi1()
              if( newVal.RAW.is_goal_effect_a ){
                this.audio.src = require("../../../assets/voice/hanabi100.mp3");
                this.audio.play();
              }
              if( newVal.RAW.is_goal_effect_b ){
                this.audio.src = require("../../../assets/voice/hanabi200_300.mp3");
                this.audio.play();
              }
            }
            this.prevPercentage = newVal.RAW.current_percentage;
          }
          // ギフト演出
          this.playMotion(newVal);
          this.giftTimer = setTimeout(function () {
            _this.videoGiftShow = false
          }, 2000)
        }
      }
    },
    isLiveOver(newVal) {
      if(newVal) {
        this.$refs.videoRect.stopVideo();
      }
    },
    boosterMessageShow(newVal) {
      if (newVal) {
        clearTimeout(this.boosterTitleHideTimer);
        clearTimeout(this.boosterTitleShowTimer);
        let _this = this;
        _this.boosterTitleShow = true;
        let showFunc = () => {
            _this.boosterTitleShow = true
            this.boosterTitleHideTimer = setTimeout(()=>{
              _this.boosterTitleShow = false
            }, 12 * 1000)
        }
        const repeatCount = 5
        for (let index = 0; index < repeatCount; index++) {
          if ( index == 0 ){
            showFunc();
            continue;
          }
          this.boosterTitleHideTimer = setTimeout(()=>{
            showFunc()
          }, (30 *index) * 1000)
        }
      }
    },
  },
  async beforeMount() {},
  mounted() {
    const rectPixi = this.$refs['rectPixi'];
    this.particleContainer = Object.seal(initPixi(window.devicePixelRatio,rectPixi,this.updateFrame,this.onAnimComplete))

    // clipboard コピーボタン
    this.clipboard = new Clipboard('.url');
    this.clipboard.on('success', () => {
      this.$message({
          showClose: true,
          message: 'コピー成功',
          type: 'success'
        });
    });
    this.clipboard2 = new Clipboard('.key');
    this.clipboard2.on('success', () => {
      this.$message({
          showClose: true,
          message: 'コピー成功',
          type: 'success'
        });
    });

    // ブラウザが閉じるあるいは別のところへ遷移する時をListen
    window.onbeforeunload = function (e) {
      e.returnValue = '配信が終了していません。閉じてもよろしいですか？';
    }
    if(this.isStarted){
      this.startLocalUpdate()
    }
  },
  async created() {
    this.initParticles();
    const liveableId = this.isLiveClubfun ? this.$route.query.id : null;
    const hasStreamingLive = await this.$store.dispatch('live/getStreamingLive',{
      liveType: this.liveType,
      liveableId: liveableId,
    });
    if (hasStreamingLive && !this.isLiveOver) {
      this.$alert("配信中のライブがあります");
      this.onStartLive(true);
    }

    // LIVE既に完了したらデータ初期化
    if (this.isLiveOver && !this.isLiveClubfun) {
      this.$store.dispatch('live/reset')
      // this.$store.commit('live/SET_LIVE_STATUS', 0);
    }
    //await this.$store.dispatch('live/obsInit');
    // this.$store.commit('live/SET_LIVE_STATUS', 0);

    // try {
    //   await this.$store.dispatch('live/obsConnect');
    // } catch(err) {
    //   console.log(err)
    //   alert('OBS Client Not Found!');
    //   this.$router.back(-1);
    //   return;
    // }

    await this.init();
    if(this.isLiveNormal){
      this.$store.dispatch('live/syncTarget');
    }
    // 通常配信イベント配信
    if ((this.isLiveNormal || this.isLiveEvent) && this.$store.state.live.info.id){
      try {
        let res = await getLiveAttrInfo(this.$store.state.live.info.id);
        if (res.data.live_info) {
          this.$store.dispatch('live/updateLiveAttrInfo',res.data.live_info);
        }
      } catch(e) {
      console.log(e);
      }
    }
    // ライブポイント初期化
    if(this.isLiveNormal || this.isLiveEvent || this.isLivePaid){
      this.livePoint = this.$store.state.live.info.point;
    }

    // 初期化が終わったら後はメッセージ受信経由になる
    this.firstFlag = false
    this.initialzed = true;
  },
  updated() {
  },
  async destroyed() {
    clearInterval(this.updateInterval);
    this.stopLocalUpdate()
    // onlineCountTimer停止
    this.stopTimOnlineCounter();
    clearTimeout(this.boosterTitleHideTimer);
    clearTimeout(this.clubfunTitleHideTimer);
    clearTimeout(this.boosterTitleShowTimer);
    // OBS
    //await this.$store.dispatch('live/obsDisconnect');

    // コピーお知らせツールの削除
    this.clipboard.destroy()
    this.clipboard2.destroy()

    // ブラウザが閉じるあるいは別のところへ遷移する時のListenerをremove
    window.onbeforeunload = null
  },
  methods: {
    async init() {
      // const isRetLiveId = await this.$store.dispatch('live/syncLiveId');

      // if (isRetLiveId) {
      //   await this.$store.dispatch('live/syncLiveURL');

      //   if (await this.$store.dispatch('live/obsIsStreaming')) {
      //     this.onStartLive(true);
      //   }
      // }
      // サーバー時間
      // console.log({serverMoment:serverMoment().format('YYYY-MM-DD HH:mm:ss')})
      // this.startLocalUpdate()
    },
    async onStartLive(isContinue = false) {
      this.onPanelClose()
      if (this.isStarted && !isContinue) {
        this.$alert("配信は既に開始しました。")
        return;
      }
      // イベント配信数が上限になっていたら、配信できない
      if (this.isLiveEvent) {
        const { data } = await getLiveListEventEntryExpiry();
        if (data && !data.can_broadcast_event) {
          this.$alert("配信できる回数の上限に達したので配信できません。")
          return;
        }
      }
      // this.$store.commit('live/SET_LIVE_STATUS', 1);
      if (!isContinue) {
        let reservedOverrideId = undefined;
        if( this.isLiveEvent && this.$route.query.reserve_id){
          const ret = await getLiveAllListS1();
          const reservedLiveOne = ret.data.data.filter((v) => {
            return v.id == this.$route.query.reserve_id && v.liveable_type === 'NORMAL';
          });
          // 予約配信として登録がある
          if( reservedLiveOne[0] && reservedLiveOne[0].schedule_start_time ) {
            reservedOverrideId = reservedLiveOne[0].id;
          }
        }
        await this.$store.dispatch('live/createLive', {
          liveType: this.liveType,
          id: this.$route.query.id,
          duration: this.liveDurationPlan,
          reservedOverrideId
        });
        // await this.$store.dispatch('live/obsSetPushConfig');
        // await this.$store.dispatch('live/obsStartStreaming');
      }
      // タッゲートあるなら３秒毎に更新
      if(this.$store.state.live.info.target.id && this.isStarted) {
        this.onlineSyncTarget()
      }
      // Event ランク３秒毎に更新
      if(this.$store.state.live.info.liveable_type == "EVENT" && this.isStarted) {
        this.syncEventRank()
      }
      if(this.isStarted) {
        this.startTimerOnlineCounter()
        this.startLocalUpdate()
      }else{
        console.error('start live failed.')
      }
      // 通常配信イベント配信
      if (this.isLiveNormal || this.isLiveEvent){
          // 1秒毎の更新
          const self = this;
          this.updateInterval = setInterval(() =>
          {
            // 属性更新各種
            const isActive = self.isAttrActive();
            if( isActive || self.prevAttrInfoActive ){
                // ブースター更新
                self.$store.dispatch('live/updateLiveAttrInfo', _.cloneDeep(self.$store.state.live.liveAttributeInfo) );
            }
            self.prevAttrInfoActive = isActive;
          }, 1000);
      }

      // 拡散テキスト設定
      if(this.$store.state.live.info.room_info && this.$store.state.live.info.room_info.twitter_text) {
        this.shareText = this.$store.state.live.info.room_info.twitter_text + "\n";
      }
      this.shareText += this.$store.state.live.info.room_info.name + "\n";
      // 環境毎のurl設定
      let url = "https://a-live.tokyo/top/live/";
      if ( isDev() || isLocal() ){
        url = "https://dev.a-live.tokyo/top/live/";
      } else if( isStg() ){
        url = "https://stg.a-live.tokyo/top/live/";
      }
      let type = ""
      if( this.isLiveNormal ){
        type = "normal";
      }
      if( this.isLiveEvent ){
        type = "event";
      }
      if( this.isLivePaid ){
        type = "paid";
      }
      if( this.isLiveClubfun ){
        type = "clubfun";
      }
      this.shareUrl = url + type + "/" + this.$store.state.live.info.id + "?liveable_id=" + this.$store.state.live.info.liveable_id;
      this.$nextTick(function() {
        this.share = this.$refs.shareLink.createSnsUrl();
      });
      // this.$store.commit('live/SET_LIVE_STATUS', 2);

      await this.$refs.comment.init();
      await this.$refs.commentlist.init();
      await this.$refs.videoRect.playVideo(!isContinue);
    },

    async stopLive() {
      // 既に停止状態であれば何もせず
      if (this.$store.getters.enum_list.live_status_list.ENDED == this.$store.state.live.info.status) {
        this.$alert("配信は既に停止しました。")
        return;
      }

      // this.$store.commit('live/SET_LIVE_STATUS', 3);
      // 停止確認ポップアップを閉じる
      this.setPanelCloseConfirm(false);
      // OBSを閉じる
      //await this.$store.dispatch('live/obsStopStreaming');
      //console.log("OBSを閉じる");
      // ライブ停止サービスを呼ぶ
      await this.$store.dispatch('live/stopLive');
      // console.log("ライブ停止サービスを呼ぶ");
      // 閲覧者数を取得するapiターマーを停止
      this.stopTimOnlineCounter();
      // console.log("閲覧者数を取得するapiターマーを停止");
      // ビデオを停止
      this.$refs.videoRect.stopVideo();
      // console.log("ビデオを停止");
      // 閉じたライブの情報を更新
      await this.$store.dispatch('live/syncLiveInfo',this.$store.state.live.info.id)
      // console.log("閉じたライブの情報を更新")
    },

    playAnimPixi() {
      window.objDynamic.visible = true
      window.objDynamic.x = 100
      window.objDynamic.y = 100
    },

    getLiveDurationStrCheckFalse(value) {
      return getLiveDurationStrCheckFalse(value);
    },

    setPanelCloseConfirm(value) {
      if (value) {
        this.ui.target = false;
        // this.ui.block = false;
        this.ui.profile = false;
      }
      this.ui.stopConfirm = value;
    },

    setPanelTarget() {
      this.ui.target = !this.ui.target;
      this.ui.stopConfirm = false;
      // this.ui.block = false;
      this.ui.profile = false;
    },

    // setPanelBlock(value) {
    //   this.ui.block = value;
    //   if (value) {
    //     this.ui.target = false;
    //     this.ui.stopConfirm = false;
    //     this.ui.profile = false;
    //   }
    // },
    setPanelProfile(bShow){
      this.ui.profile = bShow;
      if(bShow){
        this.ui.target = false;
        this.ui.stopConfirm = false;
        // this.ui.block = false;
      }

    },
    onProfileClose(){
      this.setPanelProfile(false);
    },

    onPanelClose() {
      this.ui.target = false;
    },

    onTarget() {
      if (!this.$store.state.live.info.target.id) {
        window.alert('現在のターゲットはありません。')
        return;
      }
      this.setPanelTarget();
    },

    onClubfun() {
      let link = this.$router.resolve({ name: 'SettingClubfunDetailIndex', params: {liveable_id: this.$route.query.id} });
      window.open(link.href,'_blank');
    },

    onEvent() {
      let link = this.$router.resolve({ name: 'SettingEventManageDetail', params: { id: this.$route.query.id } });
      window.open(link.href,'_blank');
    },

    onAvatarClick(profile_id) {
      // this.setPanelBlock(true);
      this.showingProfileId = profile_id
      this.setPanelProfile(true)
    },

    // onClose() {
    //   this.setPanelBlock(false);
    // },
    onShare(){
      if( this.share ){
        window.open(this.share, '_blank')
        return
      }
      this.$alert("配信開始後に使用可能となります。")
    },

    async onCommentAvatarClick(item) {
      this.commentItem = item
      this.showingProfileId = JSON.parse(this.commentItem.payload.extension).PROFILE_ID
      if(this.showingProfileId === this.$store.state.user.profileId){
        //is self ,do nothing
        return;
      }
      this.setPanelProfile(true)
    },
    // async onBlockButtonClick(){
    //   this.setPanelBlock(true);
    // },
    // async onAvatarConfirm() {
    //   await addBlock(JSON.parse(this.commentItem.payload.extension).RAW.viewer_id);
    //   this.setPanelBlock(false);
    // },

    async updateOnlineCount() {
      await this.$store.dispatch('live/timUpdateOnlineCount');
    },

    startTimerOnlineCounter() {
      this.onlineCounterTimer = setInterval(() => {
        this.updateOnlineCount();
      }, 5 * 1000);
    },

    stopTimOnlineCounter() {
      if (this.onlineCounterTimer) {
        clearInterval(this.onlineCounterTimer);
      }
      // if (this.timerLocalUpdate) {
      //   clearInterval(this.timerLocalUpdate)
      // }
      if (this.timerSyncTarget) {
        clearInterval(this.timerSyncTarget)
      }
      if (this.timerSyncEventRank) {
        clearInterval(this.timerSyncEventRank)
      }
      if (this.giftTimer) {
        clearTimeout(this.giftTimer);
      }
    },
    startLocalUpdate(){
      this.expireTime = this.$store.state.live.info.expire_time ? moment(this.$store.state.live.info.expire_time):undefined
      // this.testExpireTime = moment(serverMoment()+20000)
      if(!this.timerLocalUpdate){
        this.timerLocalUpdate = setInterval(this.localUpdate,this.LOCAL_UPDATE_INTERVAL_SECOND * 1000);
      }
    },
    stopLocalUpdate(){
      clearInterval(this.timerLocalUpdate)
    },
    localUpdate() {
      if(this.isLiveOver){
        this.onLiveEnded()
        return
      }
      if(serverMoment().isAfter(this.expireTime)){
        this.tryStopLive()
        return
      }
    },
    async tryStopLive(){
      await this.$store.dispatch('live/syncLiveInfo',this.$store.state.live.info.id)
      if(!this.isLiveOver){
        this.stopLive()
      }
    },
    onLiveEnded(){
      this.$alert("配信終了しました")
      this.stopLocalUpdate()
    },
    onlineSyncTarget() {
      this.timerSyncTarget = setInterval(() => {
        this.$store.dispatch('live/syncTarget');
      },3000);
    },
    syncEventRank() {
        this.timerSyncEventRank = setInterval(() => {
          this.$store.dispatch('live/syncLiveEventRank');
        },3000);
    },
    getAttribute(item){
      return item.profile.attribute
    },
    // isNotSelf(id){
    //   return this.$store.state.user.profile_id !== id
    // },
    // async onFollow(id) {
    //   await this.$store.dispatch("user/addFollowing", id);
    //   await this.$store.dispatch('live/syncTarget');
    // },
    // async onFollowCancel(id) {
    //   await this.$store.dispatch("user/removeFollowers", id);
    //   await this.$store.dispatch('live/syncTarget');
    // },
    async playEarthEffect() {
      this.playByKey('Tiger_back')
    },
    async playZeus(index) {
      this.playByKey('Zeus',index)
    },
    async playBall() {
      this.playByKey('Ball')
    },
    async playMeerkat() {
      this.playByKey('Meerkat')
    },
    async playGoddess(index) {
      this.playByKey('NvShen',index)
    },
    async playPhoenix() {
      this.playByKey('Phoenix')
    },
    async playPinkShit() {
      this.playByKey('PinkShit')
    },
    async playIceDragon() {
      this.playByKey('IceDragon')
    },
    async playDolphin() {
      this.playByKey('Dolphin')
    },
    async playWhale() {
      this.playByKey('Whale')
    },
    async playWildPig() {
      this.playByKey('WildPig')
    },
    async playTiger() {
      this.playByKey('Tiger')
    },
    async playFlyHorse() {
      this.playByKey('FlyHorse')
    },
    async playHanabi1() {
      this.playByKey('yanhua_a');
    },
    async playHanabi2() {
      this.playByKey('yanhua_b');
    },
    async playByKey(key,allGiftBonus=0,index = 0){
      this.playQ.push({key,allGiftBonus,index})
      if(this.playQ.length === 1){
        this.playAllAnims(key,allGiftBonus, index)
      }
    },
    playEffect(key) {
      switch(key) {
        case 'IceDragon':
          this.playSnowEffect(false, 3000);
          break
        case 'Phoenix':
          this.playFireEffect();
          break
        case 'FlyHorse':
          this.playWindEffect();
          break
        default:
          break;
      }

    },
    playAllAnims(key,allGiftBonus,index) {
      // ミッションコンプリート演出
      let time = 1;
      if( allGiftBonus > 0 ){
        const self = this;
        // 魔獣判定
        if( allGiftBonus >= 20000 ){
          this.isMissionCompleteForBeast = true;
          time = 3;
        }
        setTimeout(() => {
          this.isPlayingMissionComplete = true;
          setTimeout(()=>{
            self.isPlayingMissionComplete = false
            self.isMissionCompleteForBeast = false
          }, 5 * 1000)
        }, time * 1000);
      }
      switch(key) {
        case 'Whale':
          setTimeout(() => {  playSpineAnimation(key); }, 2000);
          break
        case 'PinkShit':
          setTimeout(() => {  playSpineAnimation(key); }, 800);
          break
        case 'Snow':
          this.playSnowEffect(true, 0);
          return;
        case 'Fire':
          this.playFireEffect(true);
          return;
        case 'Wind':
          this.playWindEffect(true, true);
          return;
        default:
          playSpineAnimation(key);
          break;
      }
      this.showBackground(key)
      this.playMusic(key,index)
      this.playEffect(key)
    },
    onAnimComplete(){
      this.playQ.shift()
      // check if the playQ have not play anim
      this.backgroundImgShow = false;
      if(this.playQ.length > 0){
        this.playAllAnims(this.playQ[0].key,this.playQ[0].allGiftBonus, this.playQ[0].index)
      }
    },
    updateFrame(){
        const newNow = Date.now();
        let updateCounter = (newNow - this.notTimeStamp) * 0.001
        // 炎
        if( this.effectFireEmmiter ){
          this.effectFireEmmiter.update(updateCounter);
        }
        // 雪
        if( this.effectSnowEmmiter ){
          this.effectSnowEmmiter.update(updateCounter);
        }
        // 風
        if ( this.effectWindAirEmmiter ){
          this.effectWindAirEmmiter.update(updateCounter);
          this.effectWindFlowerEmmiter1.update(updateCounter);
          this.effectWindFlowerEmmiter2.update(updateCounter);
        }

        this.notTimeStamp = newNow;
    },

    beastPhoenixImage(){
      return this.isBeastPhoenix? "/img/z-phoenix.png" : "/img/z-phoenix_n.png";
    },
    beastDragonImage(){
      return this.isBeastDragon? "/img/z-dragon.png" : "/img/z-dragon_n.png";
    },
    beastPegasusImage(){
      return this.isBeastPegasus? "/img/z-pegasus.png" : "/img/z-pegasus_n.png";
    },
    beastWhiteTigerImage(){
      return this.isBeastWhiteTiger? "/img/z-white-tiger.png" : "/img/z-white-tiger_n.png";
    },
    attributeFireImage(){
      return this.isAttributeFire? "/img/z-fire.png" : "/img/z-fire_n.png";
    },
    attributeIceImage(){
      return this.isAttributeIce? "/img/z-ice.png" : "/img/z-ice_n.png";
    },
    attributeWindImage(){
      return this.isAttributeWind? "/img/z-wind.png" : "/img/z-wind_n.png";
    },
    attributeEarthImage(){
      return this.isAttributeEarth? "/img/z-earth.png" : "/img/z-earth_n.png";
    },

    async initParticles() {
      if( !this.isLiveNormal && !this.isLiveEvent  ){
        return;
      }
      // 炎
      const effectFireTextures = [
        PIXI.Texture.from("/pixi/particles/fire/fire_01.png"),
        PIXI.Texture.from("/pixi/particles/fire/fire_02.png"),
        PIXI.Texture.from("/pixi/particles/fire/fire_03.png"),
        PIXI.Texture.from("/pixi/particles/fire/fire_04.png")
      ];
      let res = undefined;
      try{
        // 演出用json取得
        res = await getFireEffect();
      } catch(e){
        console.log(e);
      }
      this.effectFireOption = res.data;
      this.effectFireEmmiter = new Emitter(
        this.particleContainer,
        effectFireTextures,
        this.effectFireOption,
      );
      this.effectFireEmmiter.emit = false;
      // 雪
      const effectSnowTextures = [
        PIXI.Texture.from("/pixi/particles/snow/snow_01.png")
      ];
      res = undefined;
      try{
        // 演出用json取得
        res = await getSnowEffect();
      } catch(e){
        console.log(e);
      }
      this.effectSnowOption = res.data;
      this.effectSnowEmmiter = new Emitter(
        this.particleContainer,
        effectSnowTextures,
        this.effectSnowOption,
      );
      this.effectSnowEmmiter.emit = false;
      // 風
      const effectWindAirTextures = [
        PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_01.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_02.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_03.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_03.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_03.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_03.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_03.png"),
        // PIXI.Texture.from("/pixi/particles/wind/wind_air/wind_air_03.png")
      ];
      const effectWindFlowerTextures = [
        PIXI.Texture.from("/pixi/particles/wind/wind_flower/wind_flower_01.png")
      ];
      let res1 = undefined;
      let res2 = undefined;
      let res3 = undefined;
      try{
        // 演出用json取得
        res1 = await getWindAirEffect();
        res2 = await getWindFlowerEffect();
        res3 = await getWindFlower2Effect();
      } catch(e){
        console.log(e);
      }
      this.effectWindAirOption = res1.data;
      this.effectWindFlowerOption = res2.data;
      this.effectWindFlower2Option = res3.data;
      this.effectWindAirEmmiter = new Emitter(
        this.particleContainer,
        effectWindAirTextures,
        this.effectWindAirOption,
      );
      this.effectWindAirEmmiter.emit = false;
      this.effectWindFlowerEmmiter1 = new Emitter(
        this.particleContainer,
        effectWindFlowerTextures,
        this.effectWindFlowerOption,
      );
      this.effectWindFlowerEmmiter1.emit = false;
      this.effectWindFlowerEmmiter2 = new Emitter(
        this.particleContainer,
        effectWindFlowerTextures,
        this.effectWindFlower2Option,
      );
      this.effectWindFlowerEmmiter2.emit = false;
    },

    playFireEffect(call_next = false) {
      this.effectFireEmmiter.cleanup();
      if( this.effectFireEmmiter.emit ){
        return;
      }
      this.effectFireEmmiter.emit = true;
      setTimeout(() => {
        this.effectFireEmmiter.emit = false;

        if (call_next) {
          setTimeout(() => {
            this.onAnimComplete();
          }
            // 2秒
            ,this.effectWaitDuration
          )
        }
        }
        // 5秒
        ,this.effectDuration
      )
      // 炎の音
      this.attributeAudio.src = require("../../../assets/voice/fire.mp3");
      this.attributeAudio.play();
    },

    playSnowEffect(call_next = false, duration = 0) {
      this.effectSnowEmmiter.cleanup();
      if( this.effectSnowEmmiter.emit ){
        return;
      }
      this.effectSnowEmmiter.emit = true;
      setTimeout(() => {
        this.effectSnowEmmiter.emit = false;
        if (call_next) {
          setTimeout(() => {
              this.onAnimComplete();
            }
              // 2秒
              ,this.effectWaitDuration
            )
          }
        }
        // 5秒
        ,duration > 0 ? duration: this.effectDuration
      )
      // 雪の音
      this.attributeAudio.src = require("../../../assets/voice/snow.mp3");
      this.attributeAudio.play();
    },

    playWindEffect(call_next = false, is_short = false) {
      let wait1 = 500
      let wait2 = 2500
      let wait3 = 2000
      if (is_short) {
        wait1 = 200
        wait2 = 1500
        wait3 = 1200
      }
      this.effectWindAirEmmiter.cleanup();
      this.effectWindFlowerEmmiter1.cleanup();
      this.effectWindFlowerEmmiter2.cleanup();
      // if( this.effectWindAirEmmiter.emit ){
      //   return;
      // }
      this.effectWindAirEmmiter.emit = true;
      setTimeout(() => {
        this.effectWindFlowerEmmiter1.cleanup();
        this.effectWindFlowerEmmiter1.emit = true;
        // 風の音
        this.attributeAudio.src = require("../../../assets/voice/wind.mp3");
        this.attributeAudio.play();
        setTimeout(() => {
          this.effectWindFlowerEmmiter2.cleanup();
          this.effectWindFlowerEmmiter2.emit = true;
          setTimeout(() => {
            this.effectWindAirEmmiter.emit = false;
            this.effectWindFlowerEmmiter1.emit = false;
            this.effectWindFlowerEmmiter2.emit = false;

            if (call_next) {
              setTimeout(() => {
                this.onAnimComplete();
              }
                ,this.effectWaitDuration
              );
            }
          }
            ,wait3
          );
        }
          ,wait2
        );
      }
        ,wait1
      );
    },

    playMotion(gift){
      let allGiftBonus = 0;
      // ミッションコンプリート演出用
      if( gift.RAW.all_gift_bonus > 0 ){
        allGiftBonus = gift.RAW.all_gift_bonus;
      }
      for(let i=0; i<gift.RAW.amount; i++) {
        // ギフト演出
        let key = '';
        let index = 0;
        switch( gift.RAW.type ){
          case this.$store.state.system.enum_list.gift_type_list.SHOW_FIRE:
            key = 'Fire'
            break;
          case this.$store.state.system.enum_list.gift_type_list.SHOW_ICE:
            key = 'Snow'
            // this.playSnowEffect();
            break;
          case this.$store.state.system.enum_list.gift_type_list.SHOW_WIND:
            key = 'Wind'
            // this.playWindEffect(true);
            break;
          case this.$store.state.system.enum_list.gift_type_list.SHOW_EARTH:
            key = 'Tiger_back'
            break;
          case this.$store.state.system.enum_list.gift_type_list.SUPERLATIVE_GODDESS:
            key = 'NvShen'
            index = i % 3
            break;
          case this.$store.state.system.enum_list.gift_type_list.SUPERLATIVE_ZEUS:
            key = 'Zeus'
            index = i % 3
            break;
          case this.$store.state.system.enum_list.gift_type_list.BALLOON_GROUP:
            key = 'Ball'
            break;
          case this.$store.state.system.enum_list.gift_type_list.MEERKAT_3:
            key = 'Meerkat'
            break;
          case this.$store.state.system.enum_list.gift_type_list.POOP_PINK:
            key = 'PinkShit'
            break;
          case this.$store.state.system.enum_list.gift_type_list.DOLPHIN_3:
            key = 'Dolphin'
            break;
          case this.$store.state.system.enum_list.gift_type_list.WHALE:
            key = 'Whale'
            break;
          case this.$store.state.system.enum_list.gift_type_list.BEAST_PEGASUS:
            key = 'FlyHorse'
            break;
          case this.$store.state.system.enum_list.gift_type_list.BEAST_PHOENIX:
            key = 'Phoenix'
            break;
          case this.$store.state.system.enum_list.gift_type_list.BEAST_DRAGON:
            key = 'IceDragon'
            break;
          case this.$store.state.system.enum_list.gift_type_list.BOAR_GROUP:
            key = 'WildPig'
            break;
          case this.$store.state.system.enum_list.gift_type_list.BEAST_WHITETIGER:
            key = 'Tiger'
            break;
          default:
            break;
        }
        if( key ){
          this.playByKey(key,allGiftBonus,index);
        }
      }
    },
    videoGiftHide() {
      this.videoGiftShow = false;
    },
    playMusic(type,index = 0) {
      switch(type) {
        case 'Ball':
          this.motionAudio.src = require("../../../assets/voice/Ball.mp3");
          break;
        case 'Whale':
          this.motionAudio.src = require("../../../assets/voice/Whale.mp3");
          break;
        case 'Dolphin':
          this.motionAudio.src = require("../../../assets/voice/Dolphin.mp3");
          break;
        case 'Phoenix':
          this.motionAudio.src = require("../../../assets/voice/Phoenix.mp3");
          break;
        case 'WildPig':
          this.motionAudio.src = require("../../../assets/voice/WildPig.mp3");
          break;
        case 'Meerkat':
          this.motionAudio.src = require("../../../assets/voice/Meerkat.mp3");
          break;
        case 'NvShen':
          this.motionAudio.src = require(`../../../assets/voice/NvShen_${index + 1}.mp3`);
          break;
        case 'Zeus':
          this.motionAudio.src = require(`../../../assets/voice/Zeus_${index + 1}.mp3`);
          break;
        case 'PinkShit':
          this.motionAudio.src = require("../../../assets/voice/PinkShit.mp3");
          break;
        case 'FlyHorse':
          this.motionAudio.src = require("../../../assets/voice/FlyHorse.mp3");
          break;
        case 'IceDragon':
          this.motionAudio.src = require("../../../assets/voice/IceDragon.mp3");
          break;
        case 'Tiger_back':
          this.motionAudio.src = require("../../../assets/voice/earth.mp3");
          break;
        case 'Tiger':
          this.motionAudio.src = require("../../../assets/voice/Tiger.mp3");
          break;
        default:
          this.motionAudio = new Audio()
          break;
      }
      this.motionAudio.play();
    },
    showBackground(type){
      this.giftType = type;
      this.backgroundImgShow = true;
    },
    isAttrActive(){
      const attrInfo = this.$store.state.live.liveAttributeInfo
      if( !attrInfo ){
        return false;
      }
      const now = moment().unix();
      const arr = [
        now,
        moment(attrInfo.booster_time??0).unix(),
        moment(attrInfo.all_gift_booster_time??0).unix(),
        moment(attrInfo.ice_time??0).unix(),
        moment(attrInfo.wind_time??0).unix(),
        moment(attrInfo.earth_time??0).unix(),
        moment(attrInfo.phoenix_time??0).unix(),
        moment(attrInfo.dragon_time??0).unix(),
        moment(attrInfo.pegasus_time??0).unix(),
        moment(attrInfo.phoenix_time??0).unix(),
      ]
      return Math.max(...arr) != now;
    },
    getClubfunPercentageTensCoinImg() {
      return '/img/clubfun_coin_par/' + this.$store.state.live.current_percentage.tens + '.png'
    },
    getClubfunPercentageOnesImg() {
      return '/img/clubfun_coin_par/g' + this.$store.state.live.current_percentage.ones + '.png'
    },
    getClubfunPercentageTensImg() {
      return '/img/clubfun_coin_par/g' + this.$store.state.live.current_percentage.tens + '.png'
    },
    getClubfunPercentageHundredsImg() {
      return '/img/clubfun_coin_par/g' + this.$store.state.live.current_percentage.hundreds + '.png'
    },
    getClubfunPercentageThousandsImg() {
      return '/img/clubfun_coin_par/g' + this.$store.state.live.current_percentage.thousands + '.png'
    },
    playClubfunTitle() {
      const Process = Math.floor(this.$store.state.live.liveClubfunProcess/50) * 50
      this.scrollClubfunMessage = [{'title': Process}]
      let _this = this;
      _this.clubfunTitleShow = true
      let showFunc = () => {
        _this.clubfunTitleShow = true
        this.clubfunTitleHideTimer = setTimeout(()=>{
          _this.clubfunTitleShow = false
        }, 12 * 1000)
      }
      const repeatCount = 5
      for (let index = 0; index < repeatCount; index++) {
        if ( index == 0 ){
          showFunc();
          continue;
        }
        this.clubfunTitleHideTimer = setTimeout(()=>{
          showFunc()
        }, (30 *index) * 1000)
      }
    }
  }
};
</script>
