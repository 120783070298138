import axios from 'axios'

export function getUrlCode(url) {
    return axios.get(url).then(function (response) {
      return response.data;
    }).catch(()=> {
      // console.log(e)
      return false
    })
}
