import * as PIXI from 'pixi.js';
window.PIXI = PIXI;
import "pixi-spine";
// const Spine = require('pixi-spine') ;
let pixiApp = null;
let particleContainer = null;
let effects = {};
let animCompleteCallback = ()=>{};
// let isInited = false;

export function initPixi(devicePixelRatio,rectPixi,updateFunc,animCompleteFunc){
  pixiApp = Object.seal(
    new window.PIXI.Application({
      autoResize: true,
      resolution: devicePixelRatio || 1,
      antialias: true,
      transparent: true,
      backgroundColor: 0x000000,
      resizeTo: rectPixi
    })
  );
  pixiApp.renderer.plugins.interaction.autoPreventDefault = false
  pixiApp.renderer.view.style.touchAction = 'auto'
  rectPixi.appendChild(pixiApp.view)
  const container = new window.PIXI.Container();
  pixiApp.stage.addChild(container);
  particleContainer = container;
  pixiApp.ticker.add(updateFunc);
  animCompleteCallback = animCompleteFunc
  return particleContainer
  // isInited = true;
  // pixiApp.loader.add('Zeus', '/pixi/spine/Zeus/Zeus.json')
  //   pixiApp.loader.add('Ball', '/pixi/spine/Ball/Ball.json')
  //   pixiApp.loader.add('Meerkat', '/pixi/spine/Meerkat/Meerkat.json')
  //   pixiApp.loader.add('NvShen', '/pixi/spine/NvShen/NvShen.json')
  //   pixiApp.loader.add('Phoenix', '/pixi/spine/Phoenix/Phoenix.json')
  //   pixiApp.loader.add('PinkShit', '/pixi/spine/PinkShit/PinkShit.json')
}

export async function playSpineAnimation(resString){
  console.log('start play ' + resString)
  if(effects[resString]){
    effects[resString].visible = true;
    effects[resString].state.setAnimation(0, 'animation', false)
    // effects[resString].state.onComplete = function(trackIndex,loopCount){
    //   console.log('track1111'+trackIndex +'completed1111'+loopCount)
    // }
    return
  }
  pixiApp.loader.add(resString, `/pixi/spine/${resString}/${resString}.json`).load((loader,resources)=>{
    // TODO try to optimize this
    const animation = new window.PIXI.spine.Spine(resources[resString].spineData)
    animation.state.onComplete = function(trackIndex,loopCount){
      console.log('track'+trackIndex +'completed'+loopCount)
      // pixiApp.stage.removeChild(animation)
      animation.visible = false
      if(animCompleteCallback){
        animCompleteCallback()
      }
    }
    console.time('3333')
    pixiApp.stage.addChild(animation)
    effects[resString] = animation
    console.timeEnd('3333')
    switch(resString) {
      case 'Zeus':
        animation.x = 360
        animation.y = 350
        animation.scale.x = animation.scale.y = 1;
        break
      case 'NvShen':
        animation.x = 360
        animation.y = 900
        animation.scale.x = animation.scale.y = 1.6;
        break
      case 'Phoenix':
        animation.x = 370
        animation.y = 490
        animation.scale.x = animation.scale.y = 1;
        break
      case 'Dolphin':
        animation.x = 165
        animation.y = 830
        animation.scale.x = animation.scale.y = 1;
        break
      case 'Whale':
        animation.x = 290
        animation.y = 420
        animation.scale.x = animation.scale.y = 1;
        break
      case 'FlyHorse':
        animation.x = 360
        animation.y = 460
        animation.scale.x = animation.scale.y = 1;
        break
      case 'Tiger':
        animation.x = 370
        animation.y = 395
        animation.scale.x = animation.scale.y = 1;
        break
      case 'Tiger_back':
        animation.x = 360
        animation.y = 425
        animation.scale.x = animation.scale.y = 1;
        break
      case 'IceDragon':
        animation.x = 360
        animation.y = 540
        animation.scale.x = animation.scale.y = 1;
        break
      case 'PinkShit':
        animation.x = 360
        animation.y = 400
        animation.scale.x = animation.scale.y = 1;
        break
      case 'Meerkat':
        animation.x = 360
        animation.y = 300
        animation.scale.x = animation.scale.y = 1.2;
        break;
      case 'yanhua_a':
        animation.x = 360
        animation.y = 240
        animation.scale.x = animation.scale.y = 0.7;
        break;
      case 'yanhua_b':
        animation.x = 360
        animation.y = 240
        animation.scale.x = animation.scale.y = 0.7;
        break;
      default:
        animation.x = 360
        animation.y = 300
        animation.scale.x = animation.scale.y = 1;
        break;
    }
    // animation.x = 360
    // animation.y = 300
    if (animation.state.hasAnimation('animation')) {
      // run forever, little boy!
      animation.state.setAnimation(0, 'animation', false)
      // dont run too fast
      animation.state.timeScale = 1
    }
    pixiApp.start()
  })
}

export {particleContainer,pixiApp}
