<template lang="pug">
  .root
    img.video-sub(@click="onPlay()" src="/img/play_button.png" v-if="videoStatus==='PREPARED'") 
    button.loading-button(v-if="videoStatus==='PREPARING' || videoStatus === 'LOADING'") Loading
    video.video-rect(ref="hls-player"
      webkit-playsinline
      )

</template>

<style src="./VideoRect.scss" lang="scss" scoped>

</style>

<script>
import Hls from 'hls.js';
import {getUrlCode} from '@/api/tools';
import * as Bluebird from "bluebird";

const VIDEO_STATUS = {
  PAUSED : 'PAUSED',
  PREPARING : 'PREPARING',
  PREPARED : 'PREPARED',
  LOADING : 'LOADING',
  PLAYING : 'PLAYING',
}

export default {
  name: "LiveComponentVideoRect",
  components: {},
  data() {
    return {
      insHLS: undefined,
      videoStatus: VIDEO_STATUS.PAUSED,
      timer: undefined,
      videoTag: undefined,
      bluebirdPromiseClose: false,
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    this.syncM3U8()
    // this.timer = setInterval(this.syncM3U8, 3 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.stopVideo();
    this.bluebirdPromiseClose = true;
  },
  updated() {},
  methods: {
    onPlay() {
      this.videoStatus = VIDEO_STATUS.LOADING;
      this.insHLS.loadSource(this.$store.state.live.liveURL);
      this.videoTag.play();
      this.videoStatus = VIDEO_STATUS.PLAYING;
    },
    async playVideo() {

      this.videoStatus = VIDEO_STATUS.PREPARING;
      this.videoTag = this.$refs['hls-player'];
      let _this = this
      this.videoTag.onplay = function() {
        _this.videoStatus = VIDEO_STATUS.PLAYING
      };

      let lastRetData = null;
      let count = 2;
      for(let i = 0; i != -1 ; i++) {
        const ret = await getUrlCode(this.$store.state.live.liveURL);
        if (i === 0 && ret){
          //await this.$store.dispatch('live/obsConnect');
          console.log("succese")
          break;
        }
        if (lastRetData === null && ret) {
          lastRetData = ret;
        }
        if(this.bluebirdPromiseClose || this.$store.getters.enum_list.live_status_list.ENDED === this.$store.state.live.info.status){
          break
        }
        if (!ret) {
          await Bluebird.delay(5000);
          continue;
        }
        if (lastRetData === ret) {
          console.log("TheSomeUrl")
          await Bluebird.delay(5000);
          continue;
        }
        if (count === 0) {
          break;
        } else {
          count = count - 1;
          lastRetData = null;
        }
        i = 1;
      }
      // await Bluebird.delay(20000);
      
      this.videoStatus = VIDEO_STATUS.PREPARED;

      if (Hls.isSupported()) {
        this.insHLS = new Hls({
          autoStartLoad: true,
          startFragPrefetch: true,
          liveDurationInfinity: true,
          liveSyncDuration: 3,
          enableWorker: true,
          manifestLoadingTimeOut: 2000,
          levelLoadingTimeOut: 2000,
          levelLoadingMaxRetry: 10,
          levelLoadingRetryDelay: 50,
          levelLoadingMaxRetryTimeout: 30 * 1000,
          fragLoadingTimeOut: 2000,
          fragLoadingMaxRetry: 10,
          fragLoadingRetryDelay: 50,
          fragLoadingMaxRetryTimeout: 30 * 1000,
          xhrSetup: (xhr, url) => {
            if(url && url.split('?')[0].endsWith('.m3u8')) {
              xhr.open('GET', this.$store.state.live.liveURL, true);
            }
          },
        });
        this.insHLS.attachMedia(this.videoTag);
        this.insHLS.on(Hls.Events.MEDIA_ATTACHED,  () => {
          this.videoStatus = VIDEO_STATUS.PREPARED;
          Hls.isSupported()
            ? this.insHLS.loadSource(this.$store.state.live.liveURL)
            : (this.videoTag.src = this.$store.state.live.liveURL)
          this.videoTag.play()
        });
        this.insHLS.on(Hls.Events.MANIFEST_PARSED,  () => {
          });
        this.insHLS.on(Hls.Events.ERROR, async (event, data) => {
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                this.insHLS.startLoad();
                break;
              case 'mediaError':
                this.insHLS.recoverMediaError();
                break;
              default:
                this.insHLS.startLoad();
                break;
            }
          } else {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                if (data.response && data.response.code === 404) {
                  // this.$store.commit('live/SET_LIVE_ID', -1);
                  this.insHLS.destroy();
                }
                break;
              default:
                break;
            }
          }
        });        
      }
    },

    stopVideo() {
      if(this.insHLS) {
        this.insHLS.destroy();
      }
    },

    syncM3U8() {
      if (this.$store.state.live.info.id) {
        this.$store.dispatch('live/syncLiveURL');
      }
    },
  }
};
</script>
